<template>
  <NuxtLink
    v-if="post"
    :to="localePath(`/community/${postType}/p${post.url}`)"
    :class="[
      'post-wrapper flex-col',
      postType,
      { 'secondary-featured': isSecondaryFeatured },
      { 'main-featured': isMainPost },
      { 'cat-page': isCatPage },
    ]"
  >
    <div :class="['img-wrapper', { 'main-img': isMainPost }]">
      <!-- can't use loading image because video posters can't be resized, meaning the large image is downloaded twice -->
      <CloudinaryImage
        v-if="publicId"
        :public-id="publicId"
        :options="imageOptions"
        :alt="post.display_title"
        :is-video="isVideo"
        :image-classes-override="['header-image']"
      />
      <div v-if="isVideo" class="play-icon-wrapper flex cntr">
        <PlayFill />
      </div>
    </div>
    <div class="info-container">
      <div
        v-if="firstCategory"
        :aria-label="`View ${firstCategory.display_title} ${postType}s`"
        class="community-category pointer"
        role="button"
        @click.prevent="navToCategory(localePath(`/community/${postType}${firstCategory.url}`))"
      >
        {{ firstCategory.display_title }}
      </div>
      <component :is="isMainPost ? 'h2' : 'h3'" v-if="post.display_title" :class="['post-title', { h5: !isMainPost }]">
        {{ post.display_title }}
      </component>
      <div v-if="eventDateDisplay || videoOrBlogDateDisplay" class="event-date">
        {{ eventDateDisplay || videoOrBlogDateDisplay }}
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { CTSPost } from '~/types/contentstack';
import { getAssetPublicId, extractItems } from '~/util/contentstack/csHelpers';
import { getFirstIndex } from '~/util/arrays';
import { formatStartDate, startAndEndDate } from '~/util/date';

const localePath = useLocalePath();

const props = defineProps({
  post: {
    type: Object as () => CTSPost,
    required: true,
  },
  isMainPost: {
    type: Boolean,
    default: false,
  },
  isSecondaryFeatured: {
    type: Boolean,
    default: false,
  },
  isCatPage: {
    type: Boolean,
    default: false,
  },
});

const postType = computed<string>(() => props.post?.post_type?.post_type);
const isEvent = computed<boolean>(() => postType.value === 'event');
const imageOptions = computed<ImageOptions>(() => {
  return {
    width: props.isMainPost ? 907 : props.isSecondaryFeatured ? 120 : isEvent.value && !props.isCatPage ? 80 : 320,
    height: props.isMainPost ? 510 : props.isSecondaryFeatured ? 68 : isEvent.value && !props.isCatPage ? 80 : 180,
    quality: 'auto',
    ...(isVideo && { thumbnail: true }),
  };
});
const categories = computed<any[]>(() => extractItems(props.post?.categoriesConnection));
const firstCategory = computed<any>(() => (categories.value?.length ? categories.value[0] : null));
const isVideo = computed<boolean>(() => postType.value === 'video');
const isBlog = computed<boolean>(() => postType.value === 'blog');
const eventDateDisplay = computed<string | undefined>(() => {
  return isEvent.value && props.post?.event_date
    ? props.post?.event_end_date
      ? startAndEndDate(props.post.event_date, props.post.event_end_date)
      : formatStartDate(props.post.event_date, true)
    : undefined;
});
const videoOrBlogDateDisplay = computed<string | undefined>(() => {
  return (isVideo.value || isBlog.value) && props.post?.publish_date
    ? formatStartDate(props.post.publish_date)
    : undefined;
});
const publicId = computed<string>(() => {
  const featuredImg = isVideo.value ? getFirstIndex(props.post?.featured_image) : props.post?.featured_image;
  const publicId = isVideo.value ? featuredImg.public_id : getAssetPublicId(featuredImg);
  return publicId;
});

function navToCategory(url: string): void {
  window.open(url, '_self');
}
</script>

<style lang="scss" scoped>
.post-wrapper:hover {
  .post-title {
    color: $color-primary-600;
  }
}
:deep(.img-wrapper.main-img) {
  .header-image {
    width: auto;
  }
  @include local-mixins.tablet_and_mobile {
    width: 100vw;
    left: -16px;
    position: relative;
    .header-image {
      border-radius: 0;
    }
  }
}
:deep(.header-image) {
  @include local-mixins.tablet_and_mobile {
    width: 100%;
  }
  /* stylelint-disable-next-line no-duplicate-selectors */
  & {
    height: auto;
    border-radius: 4px;
  }
}
.info-container {
  color: $color-neutral-black;
}
.play-icon-wrapper {
  position: relative;
  height: 48px;
  width: 48px;
  top: -60px;
  margin-bottom: -48px; // offset relative position extra height
  left: 8px;
  background-color: $color-neutral-white;
  border-radius: 50%;
  :deep(svg) {
    height: 32px;
    width: 32px;
  }
}
.main-featured {
  .info-container {
    margin-top: 1rem;
  }
  @include local-mixins.tablet_and_mobile {
    padding-bottom: 1.5rem;
  }
}
.secondary-featured {
  flex-direction: row;
  :deep(.header-image) {
    width: #{local-functions.rem-calc(120)};
    margin-right: 0.75rem;
  }
  .play-icon-wrapper {
    height: 32px;
    width: 32px;
    margin-bottom: -32px; // offset relative position extra height
    top: -43px;
    :deep(svg) {
      height: 20px;
      width: 20px;
    }
  }
  + .secondary-featured {
    margin-top: 1.5rem;
    @include local-mixins.tablet_and_mobile {
      margin-top: 1.25rem;
    }
  }
}
.event-date {
  font-size: 0.875rem;
  color: $color-neutral-cool-600;
}
.post-wrapper:not(.secondary-featured):not(.main-featured) {
  &:not(.event:not(.cat-page)) .info-container {
    margin-top: 0.75rem;
  }

  &.event:not(.cat-page) {
    flex-direction: row;
    :deep(.header-image) {
      height: 80px;
      width: 80px;
      margin-right: 12px;
    }
    .info-container {
      width: max-content;
    }
  }
}
</style>
